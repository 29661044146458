/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AuthFooter from "components/Footers/AuthFooter";
import AuthNavbar from "components/Navbars/AuthNavbar";
import Sidebar from "components/Sidebar/Sidebar.js";
import Index from "views/Index.js";
import routes from "routes.js";
import Instructions from "components/Notes/Instructions";
import Usecases from "components/Notes/Usecases";
import WhatIsZip from "components/Notes/WhatIsZip";

const Home = (props) => {
  const mainContent = React.useRef(null);


  React.useEffect(() => {
 
  }, []);

  

  

  return (
    <>
      <div>
        <div className="main-content" ref={mainContent}>  
           <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <AuthNavbar />
            <Index />
            <WhatIsZip />
            <Usecases />
            <AuthFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
