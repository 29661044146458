import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from "react-router-dom";
// reactstrap components
import { Container, Row, Col, Spinner } from "reactstrap";

// core components
import AuthFooter from "components/Footers/AuthFooter.js";
import BasicNote from "components/Notes/BasicNote";
import config from '../config.js';
import QuillNavBar from "components/Navbars/QuillNavBar.js";

const Quill = (props) => {
  const mainContent = useRef(null); // Initialize ref correctly
  const location = useLocation();
  const { param } = useParams();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [quillLoading, setQuillLoading] = useState(true); // State variable to manage form loading

  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContent.current) {
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  useEffect(() => {
    if (param) {
      // Define the API Gateway endpoint
      const apiUrl = `${config.apiUrl}/${config.apiStage}/quill/${param}`;
  
      // Fetch data from API Gateway
      fetch(apiUrl)
        .then(async response => {  
          // Parse JSON response once and store it in a variable
          const responseData = await response.json();
          if (!response.ok) {
            console.error("Error response data:", responseData.error);
  
            if (response.status === 400 && responseData.error === "Invalid or missing GUUUID") {
              setMessage('This quill doesn\'t exist... \n\nJoin today to create one of your own!');
            } else {
              throw new Error(responseData.error || `HTTP error! status: ${response.status}`);
            }
            // Stop further processing by returning
            return;
          }
  
          // If response is OK, process the data
          return responseData;
        })
        .then(data => {
          if (data) {  
            if (data.message) {
              setMessage(data.message);
            } else {
              setMessage("No message available.");
            }
          }
          setQuillLoading(false);
        })
        .catch(error => {
          console.error("Fetch error:", error);
          setMessage(error.message || 'An unexpected error occurred');
          setError(error.message);
          setQuillLoading(false);
        });
    } else {
      setMessage('This is an example static Note \n\n -Grant');
      setQuillLoading(false);
    }
  }, [param]);
  
  
  

  return (
    <>
      <div className='bg-gradient-info' ref={mainContent}>
      <QuillNavBar />
        <Container className="d-flex vh-100 justify-content-center">
          <Row className="justify-content-center">
            <Col lg="12" md="3" sm="1">
            <BasicNote data={message} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Quill;
