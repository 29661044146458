/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "reactstrap";
import writingwithquill from '../assets/img/writingwithquill.webp'
import './index.css';
import quillimg from '../assets/img/brand/quillOnly.webp'
import quillimg2 from '../assets/img/brand/ZipQuillTransparent-sidebyside.png'
import { Helmet } from 'react-helmet';
import InstallPWAButton from "../components/Notes/InstallPWAButton";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  return (
    <>
 
      {/* Page content */}
      {/* <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="text-center">
            <CardBody>
              <CardTitle tag="h1">Welcome to ZipQuill</CardTitle>
                <CardText>
                  Bringing "Posting" closer to home with a local focus
                </CardText>
                <CardText>
                  Real-time messages that matter to those nearby
                </CardText>
                <CardText>
                  Don’t settle for a message... Seek a Quill and see what it holds!
                </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container> */}

<Container fluid className="mt-6 mb-3">
  <Row className="justify-content-center">
    <Col xs="12" md="8" lg="6" >
      <div className="header-text-container">
        <Helmet>
          <title>ZipQuill - Local Messaging Made Personal</title>
          <meta name="description" content="Making posting personal again, with a focus on what’s local" />
        </Helmet>
          <h1 className="header-title mt-2 mb-0" >
            <img  src={quillimg2} alt="ZipQuill" className="quill-image-white" />
          </h1>
          <Container className="mt-0">
            <Row className="justify-content-center">
              <Col xs="auto" className="text-center">
                <InstallPWAButton />
              </Col>
            </Row>
          </Container>
            <h3 className="header-sub-title mt-3">
              Making posting personal again, <br />with a focus on what’s local
            </h3>
        </div>
    </Col>
  </Row>
</Container>
    </>
  );
};

export default Index;
