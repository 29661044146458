import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Row, Col, Spinner } from "reactstrap";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("Protected Route")

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Store the attempted URL in localStorage
      const returnTo = location.pathname + location.search;
      localStorage.setItem('redirectUrl', returnTo);
      
      // Redirect the user to the Auth0 login page
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, location]);

  // Handle silent token renewal and redirect after successful login
  useEffect(() => {
    const renewToken = async () => {
      console.log("is authentiated?",isAuthenticated)
      if (isAuthenticated) {
        try {
          // Attempt to renew the token silently
          console.log("Attempting to renew token silently...");
          await getAccessTokenSilently();
          console.log("Token successfully renewed.");
          
          // Check if there is a stored redirect URL
          const redirectUrl = localStorage.getItem('redirectUrl');
          if (redirectUrl) {
            // Redirect to the original URL
            localStorage.removeItem('redirectUrl');
            navigate(redirectUrl);
          }
        } catch (error) {
          console.error('Token renewal failed', error);
          // If token renewal fails, redirect to login
          loginWithRedirect();
        }
      }
    };
    
    renewToken();
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect, navigate]);


  if (isLoading) {
    return  <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
                <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
                    <Row>
                    <Col className="text-center">
                        <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                        <div className="text-muted">Loading...</div>
                    </Col>
                    </Row>
                </Container>
                </div>
            </div>;
  }

  return isAuthenticated ? <Component {...rest} /> : null;
};

export default ProtectedRoute;
