/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import '../../assets/css/argon-dashboard-react.css'
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import './QuillNavBar.css'

const QuillNavBar = () => {
  const { loginWithRedirect, isAuthenticated, logout, user, isLoading  } = useAuth0();



  return (
    <>
      <Navbar 
        className="navbar-top navbar-horizontal navbar-dark custom-navbar-height" 
        expand="md"
      >
        <Container className="d-flex justify-content-center">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              src={require("../../assets/img/brand/ZipQuillTransparent.webp")}
              style={{ filter: 'invert(1) brightness(2)', height: '130px' }}  // Adjust image height accordingly
            />
          </NavbarBrand>
        </Container>
      </Navbar>
    </>
  );
};

export default QuillNavBar;
