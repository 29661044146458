import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Card, CardImg, CardBody, CardTitle, CardText, Container, Row, Col } from 'reactstrap';
import './Instructions.css'; // Import the custom CSS file
import writingQuill from '../../assets/img/writingQuill.webp'
import printQuill from '../../assets/img/printquill.webp'
import coffeemugquill from '../../assets/img/coffeemugquill.webp'
import updatequill from '../../assets/img/updatequill.webp'
import quillcreate from '../../assets/img/quillcreate3.webp'
import quillfound from '../../assets/img/quillfound.webp'




const Instructions = () => {
  const items = [
    {
      title: 'Create a Quill Below',
      text: 'Generate a Quill which comes with its own QR Code. \n The QR code holds a message you create',
      image: quillcreate
    },
    {
      title: 'Customize your Quill',
      text: 'Tailor the message of your Quill. \n Could be a friendly note, a reminder, or detailed instructions.',
      image: writingQuill
    },
    {
       title: 'Print or Download your Quill QR Code',
       text: 'Display your QR code on a sticker, magnet, flyer etc. Get creative and draw people to your Quill!',
       image: printQuill
    },
    {
        title: 'Did someone find your Quill?',
        text: 'Log in to see if your Quill has been discovered. Track how many people have viewed it.',
        image: quillfound
    },
    {
        title: 'Update or Refresh Your Quill!',
        text: 'Keep your Quill up-to-date by changing the message, or create a fresh one whenever you like.',
        image: updatequill
    }
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const renderTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const slides = items.map((item, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <div className=" pb-6 pt-md-4">
        <Container >
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="text-center instructions-card">
                <CardBody className= "instructions-body">
                  <CardTitle tag="h1" className="mb-0">{item.title}</CardTitle>
                </CardBody>
                <CardImg 
                   bottom 
                   src={item.image} 
                   width="100%"
                   style={{ maxHeight: '200px', objectFit: 'cover' }}
                   />
                <CardBody>
                  <CardText>{renderTextWithLineBreaks(item.text)}</CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </CarouselItem>
  ));

  return (
    <Carousel 
      activeIndex={activeIndex} 
      next={next} 
      previous={previous}
      interval={20000}
      ride="carousel"
      >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

export default Instructions;
