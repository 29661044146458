import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import './BasicNote.css'; // Import the CSS file

const BasicNote = ({ data }) => {
    const [isRevealed, setIsRevealed] = useState(false);

    useEffect(() => {
        if (data) {
            setIsRevealed(true);
        }
    }, [data]);

    return (
        <div>
            <Card className={`note ${isRevealed ? 'stop-gradient' : ''}`}>
                <CardBody className={`note-body ${isRevealed ? 'reveal' : ''}`}>
                    <CardTitle className="reveal-text">
                        {data || 'Loading...'} {/* Show loading text if data is not available */}
                    </CardTitle>
                </CardBody>
            </Card>
        </div>
    );
};

export default BasicNote;
