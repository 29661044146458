import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Col,
  Container,
  Row,
  Spinner,

} from 'reactstrap';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <div>
     <>
        <div className="main-content">  
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
              <Col className="text-center">
                <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                <div className="text-muted">Loading...</div>
              </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
  </div>;
};

export default Login;
