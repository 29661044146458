import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import '../../assets/css/argon-dashboard-react.css';
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import './AuthNavbar.css';


const AdminNavbar = () => {
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
  const location = useLocation();

  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
          <img
            alt="..."
            src={require("../../assets/img/brand/ZipQuillTransparent.webp")}
            style={{ filter: 'invert(1) brightness(2)' }}
          />
          </NavbarBrand>
          <Nav className="ml-auto navbar-nav navbar-dark" navbar>
            {isAuthenticated && user && (
              <>
                <NavItem>
                  <NavLink className="nav-link-icon text-shadow" to="/User/Home" tag={Link}>
                    <i className="ni ni-planet" />
                    <span className="nav-link-inner--text">My Quills</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon text-shadow"
                    to="/User/Profile"
                    tag={Link}
                  >
                    <i className="ni ni-single-02" />
                    <span className="nav-link-inner--text">Profile</span>
                  </NavLink>
                </NavItem>
              </>
            )}
            {isAuthenticated ? (
              <NavItem>
                <NavLink
                  className="nav-link-icon text-shadow"
                  to="#"
                  tag={Link}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default navigation
                    logout({ logoutParams: { returnTo: window.location.origin } }); // Log the user out and redirect
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span className="nav-link-inner--text">Logout</span>
                </NavLink>
              </NavItem>
            ) : (
              <>
                <NavItem>
                  <NavLink
                    className="nav-link-icon text-shadow"
                    to="#"
                    tag={Link}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: "signup",
                        },
                      });
                    }}
                  >
                    <i className="ni ni-circle-08" />
                    <span className="nav-link-inner--text">Register</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon text-shadow"
                    to="#"
                    tag={Link}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      loginWithRedirect(); // Trigger Auth0 login
                    }}
                  >
                    <i className="ni ni-key-25" />
                    <span className="nav-link-inner--text">Login</span>
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
