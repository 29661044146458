import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { FiPrinter, FiDownload, FiShoppingCart } from 'react-icons/fi'; // Import icons
import { SketchPicker} from 'react-color';
import ReactDOM from 'react-dom';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver'; // Optional to simplify download
// reactstrap components
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  CustomInput,
  Container,
  Row,
  Spinner,
  Card,
  CardBody,
  Badge
} from 'reactstrap';
// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import config from '../config';
import GenQRCode from "components/Notes/GenQRCode"
import AuthNavbar from "components/Navbars/AuthNavbar";
import ziplogo from '../assets/img/brand/zipquillrounded.webp';



import './UserEditQuill.css';

const UserEditQuill = () => {
  const location = useLocation();
  const [quillChange, setquillChange] = useState(false); // State variable to trigger useEffect
  const { param } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [error, setError] = useState(null); // Add error state
  const [guuid, setGuuid] = useState(''); // State variable to store guuid
  const [formLoading, setFormLoading] = useState(true); // State variable to manage form loading
  const [formData, setFormData] = useState({
    friendlyName: '',
    disableAfterRead: false,
    active: false,
    lastSeen: '',
    message: '',
    createdDate: '',
    lastUpdated: '',
    quillSeenCount: 0, // Initialize quillSeenCount in the formData state
  });
  const qrCodeRef = useRef(null);
  const [qrColor, setQrColor] = useState('#000000'); // Default color is black
  const [logoColor, setLogoColor] = useState('#000000'); // Default color is black
  const [isPickerVisible, setIsPickerVisible] = useState(false); // Toggle visibility
  const pickerRef = useRef(null); // Ref to the picker component

  // Detect clicks outside of the color picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsPickerVisible(false); // Close picker when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  const printQR = () => {
    navigate(`/User/printquill/${param}`, { state: { formData, param, qrColor, logoColor } });
  };

  
  const downloadQRCodeWithLogo = () => {
    // console.log('qrCodeRef.current:', qrCodeRef.current);
    if (!qrCodeRef.current) {
      console.error('qrCodeRef.current is null or undefined.');
      return;
    }
  
    // Set the scale factor (e.g., 2x for double resolution)
    const scale = 6;
  
    // Use `dom-to-image` to convert the component to an image with higher resolution
    domtoimage.toBlob(qrCodeRef.current, {
      width: qrCodeRef.current.offsetWidth * scale, // Scale the width
      height: qrCodeRef.current.offsetHeight * scale, // Scale the height
      style: {
        transform: `scale(${scale})`, // Apply the scaling to the content
        transformOrigin: 'top left', // Set origin to top-left to avoid distortion
        width: qrCodeRef.current.offsetWidth + 'px', // Keep the original width and height styling
        height: qrCodeRef.current.offsetHeight + 'px'
      }
    })
      .then(function (blob) {
        // Set the file name dynamically
        const fileName = `zipquill-${formData.friendlyName|| 'quill'}.png`;
      
        // Use `saveAs` from `file-saver` to trigger a download
        saveAs(blob, fileName);
      })
      .catch(function (error) {
        console.error('An error occurred while capturing the QR code:', error);
      });
  };



  useEffect(() => {
    // console.log('useEffect triggered');
    // console.log('param:', param);
    if (location.state && location.state.formData) {
      // Set the form data from the passed state
      setFormData(location.state.formData);
      setGuuid(location.state.param); // Assuming param was also passed
      setFormLoading(false); // No need to fetch data, just use the passed state
    } else {
      
    const fetchInitialData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const apiUrl = `${config.apiUrl}/${config.apiStage}/userquills?quill=${param}`;
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        // console.log(data);

        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        if (Array.isArray(data) && data.length > 0) {
          const quillData = data[0];
          setGuuid(quillData.guuid); // Set the guuid in state
          setFormData({
            friendlyName: quillData.friendlyName || '',
            disableAfterRead: quillData.disableAfterRead || false,
            active: quillData.active || false,
            lastSeen: quillData.last_used ? convertUTCToLocal(quillData.last_used) : '',
            message: quillData.message || '',
            lastUpdated: quillData.last_updated ? convertUTCToLocal(quillData.last_updated) : '',
            createdDate: quillData.created_date ? convertUTCToLocal(quillData.created_date) : '',
            quillSeenCount: quillData.quill_seen_count || 0, // Store quill_seen_count in the state
          });
        }
        setFormLoading(false); // Set form loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError(error.message); // Set error message
        setFormLoading(false); // Set form loading to false even if there's an error
      }
    };

    if (param && !formData.friendlyName) {
      fetchInitialData();
    }
  }
  }, [param, getAccessTokenSilently]);

  const convertUTCToLocal = (utcDateTime) => {
    // Parse the UTC date string
    const [datePart, timePart] = utcDateTime.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    // Convert to local time
    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    return localDate.toISOString().slice(0, 16); // Format for datetime-local input
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/userquills/${param}`;
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      // console.log('Form submitted successfully:', result);
      navigate('/User/Home');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleDelete = async (guuid) => {
    const confirmDelete = window.confirm("Confirm delete this quill?");
    if (!confirmDelete) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/userquills/${guuid}`;
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // console.log('Delete response:', data);
      setquillChange(prev => !prev); // Trigger the useEffect to run again
      navigate('/User/Home');
    } catch (error) {
      console.error('Delete API call failed:', error);
    }
  };

  const handleCancel = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleChangeColorQR = (color) => {
    setQrColor(color.hex); // Update QR color
  };
  const handleChangeColorLogo = (color) => {
    setLogoColor(color.hex); // Update QR color
  };
    // Toggle the color picker visibility on row click
    const togglePicker = () => {
      setIsPickerVisible(!isPickerVisible);
    };

  if (error) {
    return ( // Use return here to properly return the JSX
      <>
        <div className="main-content">  
          <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
                <Col className="text-center">
                  <div className="text-muted">Error: {error}</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  if (isLoading || formLoading) {
    return (
        <>
        <div className="main-content">  
        <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
              <Col className="text-center">
                <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                <div className="text-muted">Loading...</div>
              </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="main-content">  
        <AuthNavbar />
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container className="d-flex flex-column align-items-center mt-6">
            <Row onClick={togglePicker}>
              <div className="qr-button" ref={qrCodeRef}>
                <GenQRCode qrColor={qrColor} size={128} logoColor={logoColor} quill={param} friendlyName={formData.friendlyName} />
              </div>
            </Row>

            {/* Collapsible color picker */}
            {isPickerVisible && (
              <Row className="mb-2"> {/* Row for the color pickers */}
                <Col className="d-flex flex-column align-items-center mt-3">
                  <h6 className="text-white">QR Color</h6>
                  <SketchPicker 
                    color={qrColor} 
                    onChangeComplete={handleChangeColorQR} 
                    disableAlpha={true}  // Disable the alpha (transparency) slider
                  />
                </Col>
                <Col className="d-flex flex-column align-items-center mt-3 ">
                  <h6 className="text-white">Logo Color</h6>
                  <SketchPicker 
                    color={logoColor} 
                    onChangeComplete={handleChangeColorLogo} 
                    disableAlpha={true}  // Disable the alpha (transparency) slider
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-1 text-white">
              <small className="">{formData.friendlyName || "Set Friendly Name"}</small>
            </Row>
            <Row className="mt-4 mb-2 text-white qr-buttons-container">
              <Button className="p-1" onClick={printQR} style={{ width: '140px', height: '40px' }}>
                <FiPrinter style={{ marginRight: '8px', size:'24px' }} />Print
              </Button>
              <Button onClick={downloadQRCodeWithLogo} style={{ width: '140px', height: '40px' }}>
                <FiDownload style={{ marginRight: '8px' }} />Download
              </Button>
            </Row>
            <Row className="mt-4 mb-2 text-white text-center">
              <Col> {/* Use Col to align content within Row */}
                <medium className="">
                  <Link to="/store" className="text-white"> {/* Use Link component for internal navigation */}
                    <FiShoppingCart style={{ marginRight: '8px' }} />
                    Shop Quill Ideas
                  </Link>
                </medium>
              </Col>
            </Row>
          </Container>

          <Container className="mt-2">
            <Card color="secondary" className="my-1">
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup row className="mb-4">
                    <Label for="friendlyName" sm={2}>Quill Friendly Name</Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="friendlyName"
                        id="friendlyName"
                        placeholder="Enter friendly name"
                        value={formData.friendlyName}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mb-1">
                    <Label for="disableAfterRead" sm={2}>Disable after Read</Label>
                    <Col sm={10}>
                      <CustomInput
                        type="switch"
                        id="disableAfterRead"
                        name="disableAfterRead"
                        checked={formData.disableAfterRead}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mb-4">
                    <Label for="active" sm={2}>Active</Label>
                    <Col sm={10}>
                      <CustomInput
                        type="switch"
                        name="active"
                        id="active"
                        checked={formData.active}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mb-1">
                    <Label for="lastSeen" sm={2}>Last Seen</Label>
                    <Col sm={10}>
                      <Input
                        type="datetime-local"
                        name="lastSeen"
                        id="lastSeen"
                        value={formData.lastSeen}
                        readOnly
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mb-0 mt-3">
                    <Label sm={2} className="pb-0">Views  
                      <div className="smaller-text">(resets to '0' with any change or update)</div>
                    </Label>
                    <Col sm={10}>
                    <h1><Badge color="primary" className="view-count-badge">
                          {formData.quillSeenCount}
                        </Badge>
                    </h1>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mb-1 mt-4">
                    <Label for="message" sm={2}>Message</Label>
                    <Col sm={10}>
                      <Input
                        type="textarea"
                        name="message"
                        id="message"
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={handleChange}
                        maxLength={3000}
                        style={{ height: '200px' }}
                      />
                      <div className="text-muted" style={{ textAlign: 'right' }}>
                        {formData.message.length}/3000
                      </div>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mb-1 mt-4">
                    <Label for="lastUpdated" sm={2}>Last Updated</Label>
                    <Col sm={10}>
                      <Input
                        type="datetime-local"
                        name="lastUpdated"
                        id="lastUpdated"
                        value={formData.lastUpdated}
                        readOnly
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="createdDate" sm={2}>Created Date</Label>
                    <Col sm={10}>
                      <Input
                        type="datetime-local"
                        name="createdDate"
                        id="createdDate"
                        value={formData.createdDate}
                        readOnly
                      />
                    </Col>
                  </FormGroup>

                  <Row className="align-items-center d-none d-md-flex">
                    <Col className="d-flex justify-content-start flex-grow-1">
                      <Button type="submit" color="primary" className="responsive-button mr-2">Submit</Button>
                      <Button color="secondary" onClick={handleCancel} className="responsive-button dark">Cancel</Button>
                    </Col>
                    <Col className="d-flex justify-content-end flex-shrink-1">
                      <Button color="danger" onClick={() => handleDelete(param)} className="responsive-button">Delete</Button>
                    </Col>
                  </Row>

                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>

      {/* Fixed footer for mobile */}
      <div className="fixed-footer d-md-none">
        <Container>
          <Row className="align-items-center">
            <Col className="d-flex justify-content-start flex-grow-1">
              <Button type="submit" color="primary" className="responsive-button mr-2" onClick={handleSubmit}>Submit</Button>
              <Button color="secondary" onClick={handleCancel} className="responsive-button dark">Cancel</Button>
            </Col>
            <Col className="d-flex justify-content-end flex-shrink-1">
              <Button color="danger" onClick={() => handleDelete(param)} className="responsive-button">Delete</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserEditQuill;
