import React, { useState, useEffect } from 'react';
import { NavItem, Nav, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Footer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [hasAdminClaim, setHasAdminClaim] = useState(false);

  useEffect(() => {
    const checkAdminClaim = async () => {
      try {

        const token = await getAccessTokenSilently();
        const tokenPayload = JSON.parse(atob(token.split('.')[1])); // Decode JWT to get the payload
        if (tokenPayload.permissions && tokenPayload.permissions.includes('admin')) {
          setHasAdminClaim(true);
        }
      } catch (error) {
        console.error('Error checking token claim:', error);
      }
    };

    checkAdminClaim();
  }, [getAccessTokenSilently]);

  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://ZipQuill.com"
                  target="_blank"
                  style={{ color: "inherit" }} // Removes the default blue link color
                  rel="noopener noreferrer"
                >
                  ZipQuill
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <Link
                    to="/privacy"
                    className="nav-link text-light"
                    style={{ color: "inherit" }} // Removes the default blue link color
                  >
                    Privacy Policy
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/contact"
                    className="nav-link text-light"
                    style={{ color: "inherit" }} // Removes the default blue link color
                  >
                    Contact Us
                  </Link>
                </NavItem>
                {hasAdminClaim && (
                  <NavItem>
                    <Link
                      to="/admin"
                      className="nav-link text-light"
                      style={{ color: "inherit" }} // Removes the default blue link color
                    >
                      Admin
                    </Link>
                  </NavItem>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
