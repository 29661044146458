import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Row, Col, Card, CardBody, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Input } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar";
import config from '../config';

const AdminHome = (props) => {
  const mainContent = React.useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedQuills, setSelectedQuills] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  // Move fetchData function outside of useEffect
  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/admin/adminstats`;

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [getAccessTokenSilently]);

  const handleDelete = async () => {
    // Add confirmation prompt
    if (!window.confirm(`Delete ${selectedQuills.length} quill(s)?`)) {
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/admin/quills`;

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ guuids: selectedQuills })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // After successful delete, refresh the data
      setLoading(true);
      setSelectedQuills([]);  // Clear selection
      fetchData();  // Call fetchData to refresh the data
    } catch (error) {
      console.error('Error deleting quills:', error);
    }
  };

  const handleSelectQuill = (guuid) => {
    setSelectedQuills(prevState => {
      if (prevState.includes(guuid)) {
        return prevState.filter(id => id !== guuid);
      } else {
        return [...prevState, guuid];
      }
    });
  };

  if (loading) {
    return (
      <>
        <div className="main-content" ref={mainContent}>
          <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
                <Col className="text-center">
                  <Spinner color="info" />
                  <div className="text-muted">Loading...</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const filteredQuills = selectedOwner
    ? data.mostRecentQuills.filter(quill => quill.owner === selectedOwner)
    : data.mostRecentQuills;

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container className="mt-7">
            <h1 className="text-center text-light">Overall Quill Stats</h1>
            {/* First Row: Unique Owners Count and Total Quill Count */}
            <Row className="mb-2">
              <Col>
                <Card className="text-center">
                  <CardBody>
                    <h5>Unique Owners Count: {data.uniqueOwnersCount}</h5>
                    <h5>Total Quill Count: {data.totalQuillCount}</h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Second Row: User Dropdown and Unique Owners List */}
            <Row className="mb-4">
              <Col>
                <Card className="text-center">
                  <CardBody>
                    <h5>Select User</h5>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle caret color="info">
                        {selectedOwner || "Select an Owner"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {data.uniqueOwners.map((owner, index) => (
                          <DropdownItem key={index} onClick={() => setSelectedOwner(owner)}>
                            {owner}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h1 className="text-center text-light">Most Recent Quills</h1>

            {/* Delete Button */}
            <Row className="mb-4">
              <Col className="text-center">
                <Button color="danger" onClick={handleDelete} disabled={selectedQuills.length === 0}>
                  Delete Selected Quills ({selectedQuills.length})
                </Button>
              </Col>
            </Row>

            {/* Following Rows: Display Most Recent Quills One Per Row */}
            {filteredQuills.map(quill => (
              <Row key={quill.guuid}>
                <Col>
                  <Card className="my-1">
                    <CardBody>
                      <Input
                        type="checkbox"
                        checked={selectedQuills.includes(quill.guuid)}
                        onChange={() => handleSelectQuill(quill.guuid)}
                        className="mr-2"
                      />
                      <h6>{quill.owner}</h6>
                      <p>{quill.message}</p>
                      <small>Last Updated: {quill.last_updated}</small>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminHome;
