/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { Helmet } from 'react-helmet';
import './StoreView.css';
import './index.css'

const StoreView = (props) => {

  const items = [
    {
      pic: "https://m.media-amazon.com/images/I/41EuoHVn9NL._AC_.jpg", // Placeholder image
      description: "QR Code Stickers",
      hyperlink: "https://www.amazon.com/dp/B07N7MN316"
    },
    {
      pic: "https://m.media-amazon.com/images/I/71oSnEi0KPL._AC_SL1500_.jpg", // Placeholder image
      description: "Customized Magnet",
      hyperlink: "https://www.amazon.com/dp/B0CBL2R5WZ"
    },
    {
      pic: "https://m.media-amazon.com/images/I/71fujE6rUeL._AC_SX679_.jpg", // Placeholder image
      description: "Customized T-shirt",
      hyperlink: "https://www.amazon.com/dp/B0C9P39F9C"
    },
    {
      pic: "https://m.media-amazon.com/images/I/71COR7TSSmL._AC_SL1500_.jpg", // Placeholder image
      description: "Customize Arclyic Block",
      hyperlink: "https://www.amazon.com/dp/B0DBQVK5H1"
    },
    
  ];


  return (
    <>


<Container fluid className="mt-6 mb-3">
  <Row className="justify-content-center">
    <Col xs="12" md="8" lg="6" >
      <div className="header-image-container">
        <div className="header-text-container">
        <Helmet>
          <title>ZipQuill - Store</title>
          <meta name="description" content="" />
        </Helmet>
          <h3 className="header-title mt-2" >
           Showcase Your Quill!
          </h3>

          {/* Items List */}
          <div className="items-list text-center mt-6">
                  {items.map((item, index) => (
                    <li key={index} className="item mb-0">
                      <a href={item.hyperlink}>
                        <img src={item.pic} alt={`Item ${index + 1}`} className="store-img  mb-2" />
                        <p className="item-description header-sub-title">{item.description}</p>
                      </a>
                    </li>
                  ))}
          </div>
          
        </div>
      </div>
    </Col>
  </Row>
</Container>
    </>
  );
};

export default StoreView;
