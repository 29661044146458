import React, { useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import './InstallPWAButton.css';

function InstallPWAButton() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the PWA installation');
      } else {
        console.log('User dismissed the PWA installation');
      }
      setDeferredPrompt(null);
    }
  };

  return (
    <div className="install-button-container">
      {deferredPrompt && (
        <button onClick={handleInstallClick} className="install-button">
          <FaDownload size={20} />
        </button>
      )}
    </div>
  );
}

export default InstallPWAButton;
