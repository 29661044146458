import React from 'react';
import './WhatIsZip.css'; // Import the custom CSS file

// Import images
import zipquillcloud from '../../assets/img/zipquillcloud.webp';

const WhatIsZip = () => {

    
    return (
      <>
        <h1 className="whatis-title mt-4 mx-2 text-center">What is a Quill?</h1>
        <div className="whatis-list">
          <div className="whatis-item mb-4" >
            <img src={zipquillcloud} alt="WhatIsZipQuill" className="whatis-image" /> 
             <div className="whatis-content">
                  <p className="whatis-description text-center">
                  A Quill offers a simple yet powerful combination of a QR code and a customizable message. <br /> <br /> 
                  The QR code serves as a direct link to your message, which you can easily update or change 
                  whenever needed, making it ideal for sharing timely information or creating interactive social 
                  experiences.
                  </p>
              </div>
          </div>
        </div>
      </>
    );
};

export default WhatIsZip;
