import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
// reactstrap components
import { Container, Row, Col, Card, CardBody, Button, Alert, Spinner, Badge, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import config from '../config';
import './UserPrintQuill.css'; // Ensure this is imported to apply styles
import AuthNavbar from "components/Navbars/AuthNavbar";
import { FaCheckCircle, FaChevronUp, FaChevronDown  } from 'react-icons/fa'; // Import the check mark icon
import './UserHome.css';
import quillimage from '../assets/img/brand/quillOnly.webp'
import Instructions from 'components/Notes/Instructions';
import { } from 'react-icons/fa'; // Import arrow icons

const UserHome = (props) => {
  const mainContent = React.useRef(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null); // Add error state
  const [newQuill, setNewQuill] = useState(false);
  const [quillLoading, setQuillLoading] = useState(true); // State variable to manage form loading
  const [showAlert, setShowAlert] = useState(true); // State to control alert visibility
  const [idTokenClaims, setIdTokenClaims] = useState(null);
  const [stats, setStats] = useState(null); // State for stats data
  const [hasReachedMaxQuills, setHasReachedMaxQuills] = useState(false); // State for tracking if max quills reached
  const [instructionsVisible, setInstructionsVisible] = useState(false); // State for showing Instructions  const { getAccessTokenSilently, user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const { getAccessTokenSilently, user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const email = user.email;
  
        // Fetch user quills
        const quillsUrl = `${config.apiUrl}/${config.apiStage}/userquills?owner=${encodeURIComponent(email)}`;
        const quillsResponse = await fetch(quillsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!quillsResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${quillsResponse.status}`);
        }
  
        const quillsData = await quillsResponse.json();
        setData(quillsData.map(item => ({
          ...item,
          created_date: convertUTCToLocal(item.created_date),
          last_updated: item.last_updated ? convertUTCToLocal(item.last_updated) : null,
          last_used: item.last_used ? convertUTCToLocal(item.last_used) : null,
          quill_seen_count: item.quill_seen_count || 0,
        })));
  
        // Fetch stats
        const statsUrl = `${config.apiUrl}/${config.apiStage}/userstats`;
        const statsResponse = await fetch(statsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!statsResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${statsResponse.status}`);
        }
  
        const statsData = await statsResponse.json();
        setStats(statsData); // Set all stats data
  
        // Calculate if max quills has been reached
        const hasReachedMaxQuills = quillsData.length >= statsData.max_quills;
        setHasReachedMaxQuills(hasReachedMaxQuills);
  
        // Fetch ID token claims
        const claims = await getIdTokenClaims();
        setIdTokenClaims(claims);
  
        setQuillLoading(false); // Set loading to false once all data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message); // Set error message
        setQuillLoading(false);
      }
    };
  
    if (!isLoading && isAuthenticated && user) {
      fetchAllData();
    }
  }, [getAccessTokenSilently, user, isAuthenticated, isLoading, newQuill]);

  useEffect(() => {
    if (!isLoading && !quillLoading && stats && stats.total_quills === 0) {
      setInstructionsVisible(true); // Automatically show Instructions if conditions are met
    }
  }, [isLoading, quillLoading, stats]);

  const toggleInstructions = () => setInstructionsVisible(!instructionsVisible);
  
  const convertUTCToLocal = (utcDateTime) => {
    const [datePart, timePart] = utcDateTime.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    return localDate.toISOString().slice(0, 19).replace('T', ' '); // Preserve to the seconds
  };

  const handleCardClick = (guuid) => {
    navigate(`/User/editquill/${guuid}`);
  };

  const createQuill = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      setQuillLoading(true);
      const apiUrl = `${config.apiUrl}/${config.apiStage}/userquills`;
      
      const response = await fetch(apiUrl, { // Replace with your API endpoint
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      const guuid = data.guuid; // Extract the guuid from the response
  
      if (guuid) {
        navigate(`/user/editquill/${guuid}`); // Redirect to the editquill page
      }
  
      setNewQuill(prev => !prev); // Trigger the useEffect to run again if needed
    } catch (error) {
      console.error('API call failed:', error);
      setQuillLoading(false);
    }
  };

  const truncateMessage = (message, charLimit = 120) => {
    if (!message) return '';
    return message.length > charLimit ? `${message.slice(0, charLimit)}...` : message;
  };
  
  const formatDate = (utcDateTime) => {
    if (!utcDateTime) return '';
  
    const date = new Date(utcDateTime);
  
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
  
    return date.toLocaleString(undefined, options);
  };
  
  
  
  if (error) {
    return ( // Use return here to properly return the JSX
      <>
        <div className="main-content">  
          <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
                <Col className="text-center">
                  <div className="text-muted">Error: {error}</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  if (isLoading || quillLoading) {
    return (
      <>
        <div className="main-content">  
        <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
              <Col className="text-center">
                <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                <div className="text-muted">Loading...</div>
              </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="main-content">  
      <AuthNavbar />
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <div className="container mt-7">
          {hasReachedMaxQuills && showAlert && (
            <Alert color="danger" toggle={() => setShowAlert(false)}>
              You have reached your maximum number of Quills ({stats.max_quills}). <br /><br />Delete a current Quill to create a new one
            </Alert>
          )}

        {/* Arrow for showing/hiding Instructions */}
        <Row className="justify-content-end">
            <Col xs="auto" className="text-left">
              {/* Use a div with a click handler to avoid button styles */}
              <div onClick={toggleInstructions} style={{color:'white', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {instructionsVisible ? <FaChevronUp size={30} /> : <FaChevronDown size={20} />}
                <span className="ml-2">{instructionsVisible ? '' : 'Info'}</span>
              </div>
            </Col>
          </Row>

          {/* Instructions Component */}
          {instructionsVisible && (
            <Row className="justify-content-center">
              <Col xs="12">
                <Instructions />
              </Col>
            </Row>
          )}


            <Row className="justify-content-center mt-7 mb-4">
              <Col xs="auto" className="text-center">
                <Button
                  className="btn-newquill d-flex flex-column align-items-center"
                  color="secondary"
                  onClick={createQuill}
                  disabled={hasReachedMaxQuills}
                >
                  <img src={quillimage} alt="New Quill" style={{ width: '70px', height: '60px' }} />
                  {!isLoading && stats.total_quills === 0 ? (
                    <span className='mt-3'>Create Your <br />First Quill!</span>
                  ) : (
                    <span>New Quill</span>
                  )}
                </Button>
              </Col>
            </Row>

            <Row className="justify-content-end">
              <Col xs="auto">
                <Badge  
                  pill 
                  style={{ 
                    textTransform: 'none', 
                    backgroundColor: '#FFFFFF', // Light gray
                    color: '#000',               // Dark text for contrast
                    fontSize: '.8em',           // Slightly larger text
                    padding: '0.5em 1em',        // Increased padding
                  }}>
                    {stats.total_quills} / {stats.max_quills}
                </Badge>
              </Col>
            </Row>

          {data
            .sort((a, b) => new Date(b.last_updated || b.created_date) - new Date(a.last_updated || a.created_date)) // Sort by last updated, fallback to created date
            .map(item => {
              const cardColor = item.active ? 'success' : 'danger';
              const showCheckMark = item.quill_seen_count > 0 && item.last_used && new Date(item.last_used) > new Date(item.last_updated);

              return (
                <Row key={item.guuid} className="justify-content-center">
                  <Col md="8">
                    <Card
                      className="text-center my-2 position-relative"
                      color={cardColor}
                      border='10px'
                      outline
                      onClick={() => handleCardClick(item.guuid)}
                      style={{ cursor: 'pointer', border: '4px solid' }} // Change cursor to pointer to indicate clickability
                    >
                      {showCheckMark && (
                        <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', alignItems: 'center' }}>
                          {item.quill_seen_count !== undefined && (
                            <span style={{ marginRight: '5px', fontSize: '16px', color: 'green' }}>
                              ({item.quill_seen_count})
                            </span>
                          )}
                          <FaCheckCircle color="green" size={24} />
                        </div>
                      )}
                      <CardBody>
                        <h2>{item.friendlyName || 'Set a friendly name'}</h2>
                        <p>{truncateMessage(item.message)}</p> {/* Use the simpler truncateMessage function here */}
                        <medium>Updated: {formatDate(item.last_updated || item.created_date)}</medium>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              );
            })}
        </div>
      </div>
      </div>
    </>
  );
};

export default UserHome;
