import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

const Privacy = () => {
  const [policyText, setPolicyText] = useState("");
  const mainContent = React.useRef(null);

  useEffect(() => {
    // Fetch the policy text from the public directory
    fetch("/privacyPolicy.txt")
      .then((response) => response.text())
      .then((text) => setPolicyText(text));
  }, []);

  return (
    <>
      <div>
        <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <AuthNavbar />
          <Container
            style={{
              marginTop: "70px", // Move the content down
              
            }}
          >
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", color: "white" }}>
              {policyText}
            </pre>
          </Container>
          <AuthFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
