import React from 'react';
import './Usecases.css'; // Import the custom CSS file

// Import images
import footballshirt from '../../assets/img/footballshirt.webp';
import carmagnet from '../../assets/img/carmagnet.webp';
import Beach from '../../assets/img/beachqr.webp';
import lovenote from '../../assets/img/lovenote.webp';
import lunchbox from '../../assets/img/lunchbox.webp';

const Usecases = () => {
    const useCases = [
        {
            title: 'Provide Up-To-Date Relevant Info',
            description: 'Put your Quill QR code in a place where people need up-to-date information. \n\n... "Swimming conditions are great today—calm waters and no rip currents, enjoy your swim!"',
            image: Beach, // Use the imported image
        },
        {
            title: 'A Special Note For A Loved One',
            description: 'Leave a Quill for a loved one to discover. Finding and opening a personal note creates a unique and heartfelt experience, far more meaningful than just receiving a text message. \n\n... "Thanks for all you do for us! We love you Mom!"',
            image: lovenote, // Use the imported image
        }, 
        {
          title: 'Your T-shirt Is Your New Status',
          description: 'Put your Quill QR code on a shirt. For your next event, update the message to be relevant. \n\n... "Lets go Hokies! Tame the Tigers!" \n\n\n Walking out of the game, update it! \n\n ... "We won! 24-7"',
          image: footballshirt, // Use the imported image
        },
        {
          title: 'Your Car is a Mobile Dynamic Message',
          description: 'Put your Quill QR code on a magnet for your car! Update the message to share something with those around you. \n\n ... "Honk if you\'re heading to the game!"',
          image: carmagnet, // Use the imported image
        },  
        {
          title: 'One Time Use',
          description: 'Print a quill on paper or better yet a sticker. Place it for someone to find with a simple note. \n\n ... "You are worthy, you are loved! Have a great day!"',
          image: lunchbox, // Use the imported image
        }


        // Add more use cases as needed
      ];
    
    return (
        <>
        <h1 className="use-case-title my-5 mx-2 text-center">How are people using ZipQuill?</h1>

        <div className="use-case-list">
            {useCases.map((useCase, index) => (
                <div 
                  key={index} 
                  className="use-case-item mb-4" 
                  style={{ 
                    backgroundColor: index % 2 === 0 ? '#eff0f2' : '', // Alternating background color
                    color: index % 2 === 0 ? '#000000' : '#FFFFFF' // Alternating text color
                  }}
                >
                  <h5 className="use-case-title"
                  style={{ 
                    color: index % 2 === 0 ? '#000000' : '#FFFFFF' // Alternating text color
                  }}
                  >{useCase.title}</h5>
                  <img src={useCase.image} alt={useCase.title} className="use-case-image" /> {/* Image at the top */}
                  <div className="use-case-content">
                    <p className="use-case-description">{useCase.description}</p>
                  </div>
                </div>
            ))}
        </div>
        </>
    );
};

export default Usecases;
