/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';

const Header = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {

  
  }, [user]);

  return (
    <>
      {/* <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        
      </div> */}
    </>
  );
};

export default Header;
