/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "reactstrap";
import writingwithquill from '../assets/img/writingwithquill.webp'
import './index.css';
import quillimg from '../assets/img/brand/quillOnly.webp'
import quillimg2 from '../assets/img/brand/ZipQuillTransparent-sidebyside.png'
import { Helmet } from 'react-helmet';

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";

const ContactView = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  return (
    <>


<Container fluid className="mt-6 mb-3">
  <Row className="justify-content-center">
    <Col xs="12" md="8" lg="6" >
      <div className="header-image-container">
        <div className="header-text-container">
        <Helmet>
          <title>ZipQuill - Contact Us</title>
          <meta name="description" content="Making posting personal again, with a focus on what’s local" />
        </Helmet>
          <h1 className="header-title mt-2" >
            Contact Us
          </h1>
            <h3 className="header-sub-title mt-5">
               Info@ZipQuill.com
            </h3>
        </div>
      </div>
    </Col>
  </Row>
</Container>
    </>
  );
};

export default ContactView;
