/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Profile from "views/Profile.js";
import routes from "routes.js";
import AuthNavbar from "components/Navbars/AuthNavbar";


const UserProfile = (props) => {



  

  return (
      <div className="main-content" >  
      <AuthNavbar />
        <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
        <Profile />
      </div>
    </div>
  );
};

export default UserProfile;
